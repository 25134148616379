<template>
  <div class="row">
    <div class="col-lg-3">
      <b-card no-body class="card-apply-job">
        <b-card-header
          class="d-flex justify-content-between align-items-center card_title"
        >
          <h4>Search Filters</h4>
        </b-card-header>
        <b-card-body>
          <div class="">
            <b-form class="auth-login-form mt-0" @submit.prevent="handleSearch">
              <b-form-group label="Name" label-for="Name">
                <b-form-input
                  v-model="form.searchName"
                  id="Name"
                  placeholder="Search by Name"
                />
              </b-form-group>
              <b-form-group label="Job Title" label-for="designation">
                <!-- <b-form-input
                id="designation"
                placeholder="Search by Job Title"
              /> -->
                <b-form-input
                  v-model="form.selectedJobTitle"
                  id="Name"
                  placeholder="Search by Job Title"
                />
                <!-- <v-select
                  v-model="form.selectedJobTitle"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  taggable
                  push-tags
                  placeholder="Search by Job Title"
                  label="title"
                /> -->
              </b-form-group>
              <b-form-group>
                <div class="title__box">
                  <span>Company</span>
                </div>
                <div class="d-flex mb-1">
                  <b-form-checkbox
                    v-model="form.company"
                    value="true"
                    name="present"
                    class="mr-1"
                  >
                    Present
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="form.company"
                    value="false"
                    name="past"
                  >
                    Past
                  </b-form-checkbox>
                </div>
                <!-- <v-select
                  v-model="form.selectedCompany"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  taggable
                  push-tags
                  placeholder="Search by Company"
                  label="title"
                /> -->
                <b-form-input
                  v-model="form.selectedCompany"
                  id="Name"
                  placeholder="Search by Company"
                />
              </b-form-group>
              <b-form-group>
                <div class="title__box">
                  <span>Location</span>
                  <!-- <b-dropdown
                    v-model="selectedLocType"
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <feather-icon icon="FilterIcon" />
                    </template>

                    <b-dropdown-item>
                      <span class="align-middle ml-50">Citizenship</span>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <span class="align-middle ml-50">Working Visa</span>
                    </b-dropdown-item>
                  </b-dropdown> -->
                </div>
                <b-form-input
                  id="Location"
                  v-model="form.location"
                  placeholder="Search by Location"
                />
              </b-form-group>
              <b-form-group label="Institute" label-for="selectInstitute">
                <!-- <v-select
                  v-model="form.selectInstitute"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  :options="industries"
                  :get-option-label="getSelected"
                  placeholder="Search by Job Title"
                  label="title"
                /> -->
                <b-form-input
                  v-model="form.selectInstitute"
                  id="Name"
                  placeholder="Search by Institute"
                />
              </b-form-group>
              <b-form-group label="Industries" label-for="selectedIndustries">
                <v-select
                  v-model="form.selectedIndustries"
                  multiple
                  :options="industries"
                  :get-option-label="getSelected"
                  placeholder="Search by Industry"
                />
              </b-form-group>

              <b-form-group
                label="Year of Graduation"
                label-for="graduation-year"
              >
                <div class="d-flex align-items-center">
                  <span class="mr-1"> From</span>
                  <b-form-input
                    id="graduationStartDay"
                    v-model="form.graduationStartYear"
                    placeholder="Year"
                    size="sm"
                    @change="handleGraduationStartYear()"
                  />
                  <span class="mx-1">To</span>
                  <b-form-input
                    id="lastname"
                    v-model="form.graduationEndYear"
                    placeholder="Year"
                    size="sm"
                    @change="handleGraduationEndYear()"
                  />
                </div>
                <!-- <div v-if="graduation.length !== 0" class="mt-1">
                  <vue-slider
                    v-model="value2"
                    :adsorb="true"
                    :data="graduation"
                    :marks="true"
                    class="mb-3"
                    :direction="direction"
                  />
                </div> -->
              </b-form-group>

              <b-form-group label="Function" label-for="function">
                <v-select
                  v-model="form.selectedFunctions"
                  multiple
                  label="title"
                  :options="functions"
                  :get-option-label="getSelected"
                  placeholder="Search by Function"
                />
              </b-form-group>
              <b-form-group label="Sub-Function" label-for="subFunction">
                <v-select
                  v-model="form.selectedSubFunctions"
                  multiple
                  label="title"
                  :options="subFunctions"
                  :get-option-label="getSelected"
                  placeholder="Search by Sub Function"
                />
              </b-form-group>
              <b-form-group label="Skill" label-for="skill">
                <v-select
                  v-model="form.selectedSkills"
                  multiple
                  label="name"
                  :options="skills"
                  :get-option-label="getSelected"
                  placeholder="Search by Skill"
                />
              </b-form-group>
              <b-button type="submit" variant="success" block>
                Search
              </b-button>
            </b-form>
          </div>
        </b-card-body>
      </b-card>
    </div>

    <div class="col-lg-9">
      <b-card no-body class="p-1">
        <b-row>
          <b-col
            lg="5"
            md="5"
            sm="4"
            class="pb-1 my-auto d-flex justify-content-start align-items-center"
          >
            <b-form-group class="d-flex my-auto">
              <label class="d-inline text-sm-left">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
            <div>
              <span class="executive">Executive</span>
              <b-form-checkbox
                v-model="isExpert"
                checked="false"
                name="check-button"
                switch
                inline
              >
                Expert
              </b-form-checkbox>
            </div>
          </b-col>

          <b-col
            lg="7"
            md="7"
            class="pb-1 d-flex justify-content-end align-items-center"
          >
            <b-form-group
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-table
              :items="items"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              responsive
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(show_details)="row">
                <b-form-checkbox
                  v-model="row.detailsShowing"
                  class="vs-checkbox-con"
                  @change="row.toggleDetails"
                >
                  <span class="vs-label">{{
                    row.detailsShowing ? "Hide" : "Show"
                  }}</span>
                </b-form-checkbox>
              </template>
              <template #row-details="row">
                <b-card class="mb-0">
                  <div class="card__box">
                    <div class="mb-0 card__left">
                      <b-col
                        md="12"
                        v-if="
                          row.item.candidate &&
                          row.item.candidate.experiences &&
                          row.item.candidate.experiences.length !== 0
                        "
                      >
                        <div class="row pb-2">
                          <div class="y-center col-lg-2 bor-1">
                            <h6>Experience</h6>
                          </div>
                          <div class="col-lg-10">
                            <div>
                              <app-timeline
                                v-for="(experience, i) in row.item.candidate
                                  .experiences"
                                :key="i"
                              >
                                <app-timeline-item variant="primary">
                                  <div
                                    class="d-flex flex-sm-row flex-column mb-sm-0 mb-1"
                                  >
                                    <h6 class="mr-1 inline">
                                      {{ experience.designation }}
                                    </h6>
                                    <span class="mr-1 font-11 inline">at</span>
                                    <h6 class="mr-1 inline">
                                      {{ experience.company }}
                                    </h6>
                                    <span class="font-11"
                                      >{{ experience.from }}
                                    </span>
                                    <span class="font-11 mx-1"> to </span>
                                    <span class="font-11">
                                      {{ experience.to }}</span
                                    >
                                  </div>
                                </app-timeline-item>
                              </app-timeline>
                            </div>
                          </div>
                        </div>
                      </b-col>

                      <b-col
                        md="12"
                        v-if="
                          row.item.candidate &&
                          row.item.candidate.educations &&
                          row.item.candidate.educations.length !== 0
                        "
                      >
                        <div class="row pb-2">
                          <div class="y-center col-lg-2 bor-1">
                            <h6>Qualification</h6>
                          </div>
                          <div class="col-lg-10">
                            <div>
                              <app-timeline
                                v-for="(qualification, i) in row.item.candidate
                                  .educations"
                                :key="i"
                              >
                                <app-timeline-item variant="primary">
                                  <div
                                    class="d-flex flex-sm-row flex-column mb-sm-0 mb-1"
                                  >
                                    <h6 class="mr-1 inline">
                                      {{ qualification.degree }}
                                    </h6>
                                    <span class="mr-1 font-11 inline">at</span>
                                    <h6 class="mr-1 inline">
                                      {{ qualification.institute_name }}
                                    </h6>
                                    <span class="font-11">
                                      {{ qualification.from }}
                                    </span>
                                    <span class="font-11 mx-1"> to </span>
                                    <span class="font-11">
                                      {{ qualification.to }}
                                    </span>
                                  </div>
                                </app-timeline-item>
                              </app-timeline>
                            </div>
                          </div>
                        </div>
                      </b-col>

                      <b-col
                        md="8"
                        class="pb-1"
                        v-if="
                          row.item.candidate && row.item.candidate.industries
                        "
                      >
                        <div class="row">
                          <div class="y-center col-lg-3 bor-1">
                            <h6>Industry</h6>
                          </div>
                          <div class="col-lg-9">
                            <span
                              v-for="(industry, i) in JSON.parse(
                                row.item.candidate.industries
                              )"
                              :key="i"
                            >
                              <span>
                                <span v-if="i !== 0">|</span>
                                {{ industry.name }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </b-col>
                      <b-col
                        md="8"
                        class="pb-1"
                        v-if="
                          row.item.candidate && row.item.candidate.functions
                        "
                      >
                        <div class="row">
                          <div class="y-center col-lg-3 bor-1">
                            <h6>Functions</h6>
                          </div>
                          <div class="col-lg-9">
                            <span
                              v-for="(functionName, i) in JSON.parse(
                                row.item.candidate.functions
                              )"
                              :key="i"
                            >
                              <span>
                                <span v-if="i !== 0">|</span>
                                {{ functionName.name }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </b-col>
                      <b-col
                        md="8"
                        class="pb-1"
                        v-if="
                          row.item.candidate && row.item.candidate.sub_functions
                        "
                      >
                        <div class="row">
                          <div class="y-center col-lg-3 bor-1">
                            <h6>Sub Function</h6>
                          </div>
                          <div class="col-lg-9">
                            <span
                              v-for="(functionName, i) in JSON.parse(
                                row.item.candidate.sub_functions
                              )"
                              :key="i"
                            >
                              <span>
                                <span v-if="i !== 0">|</span>
                                {{ functionName.name }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </b-col>
                      <b-col
                        md="8"
                        class="pb-1"
                        v-if="row.item.candidate && row.item.candidate.skills"
                      >
                        <div class="row">
                          <div class="y-center col-lg-3 bor-1">
                            <h6>Skills</h6>
                          </div>
                          <div class="col-lg-9">
                            <span
                              v-for="(skill, i) in row.item.candidate.skills"
                              :key="i"
                            >
                              <span>
                                <span v-if="i !== 0">|</span>
                                {{ skill.name }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </b-col>
                      <b-col md="8" class="pb-1">
                        <div class="row">
                          <div class="y-center col-lg-3 bor-1">
                            <h6>Phone Number</h6>
                          </div>
                          <div class="col-lg-9">
                            <span>{{ row.item.phone }}</span>
                          </div>
                        </div>
                      </b-col>
                      <b-col md="8" class="pb-1">
                        <div class="row">
                          <div class="y-center col-lg-3 bor-1">
                            <h6>Email</h6>
                          </div>
                          <div class="col-lg-9">
                            <span>{{ row.item.email }}</span>
                          </div>
                        </div>
                      </b-col>
                    </div>

                    <div class="d-flex justify-content-between">
                      <div class="card__right mr-1">
                        <div>
                          <span> Last Updated By, </span>
                          <br />
                          <span
                            >{{
                              moment(row.item.updated_at).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )
                            }}
                          </span>
                          <p class="bold">
                            <span v-if="row.item.name">
                              {{ row.item.name }}
                            </span>
                            <span v-else>
                              {{ row.item.first_name }} {{ row.item.last_name }}
                            </span>
                          </p>
                        </div>
                        <span> Current Company, </span>
                        <br />
                        <p
                          class="bold"
                          v-if="
                            row.item.candidate.experiences &&
                            row.item.candidate.experiences.length !== 0
                          "
                        >
                          {{ row.item.candidate.experiences[0].company }}
                        </p>
                        <!-- <span> Last Contacted, </span>
                        <br />
                        <span> 27 Aug 2022, 12:30 Pm </span>
                        <p class="bold">Shahnewaj Shoumikh</p> -->
                      </div>
                      <!-- <div class="">
                        <b-dropdown
                          v-model="selectedCompanyType"
                          variant="link"
                          no-caret
                          toggle-class="p-0"
                          right
                        >
                          <template #button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="24"
                              class="cursor-pointer icon text-dark"
                            />
                          </template>
                        </b-dropdown>
                      </div> -->
                    </div>
                  </div>
                </b-card>
              </template>
            </b-table>
          </b-col>
          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </div>
    <!-- <div>
      <TestTable />
    </div> -->
  </div>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue"
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue"

import axios from "@/libs/axios"
import store from "@/store/index"
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
  BForm,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import vSelect from "vue-select"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import errorResponse from "@/libs/axiosError"

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BCardBody,
    BCardHeader,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    AppTimeline,
    AppTimelineItem,
    BForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showExperience: 3,
      showQualification: 3,
      graduationStartYear: null,
      graduationEndYear: null,
      value2: null,
      graduation: [],
      dir: "ltr",
      trueValue: true,
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      isExpert: false,
      filter: null,
      filterTwo: "",
      filterOn: [],
      selectInstitute: [],
      selectedCompany: [],
      selectedCompanyType: null,
      selectedLocType: null,
      selectedIndustries: [],
      industries: [],
      selectedFunctions: [],
      functions: [],
      selectedSubFunctions: [],
      subFunctions: [],
      selectedSkills: [],
      location: "",
      searchName: "",
      skills: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        "show_details",
        { key: "name", label: "Full Name", sortable: true },
        // { key: "candidate.title", label: "DESIGNATION", sortable: true },
        // { key: "candidate.country", label: "Country", sortable: true },
        {
          key: "candidate.current_annual_salary",
          label: "COMPENSATION",
          sortable: true,
        },
      ],
      items: [],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      searchFilter: "",
      form: {
        searchName: "",
        selectedJobTitle: "",
        company: "",
        selectedCompany: "",
        location: "",
        selectInstitute: "",
        selectedIndustries: "",
        graduationStartYear: "",
        graduationEndYear: "",
        selectedFunctions: "",
        subFunctions: "",
        selectedSkills: "",
      },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }))
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = "rtl"
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = "ltr"
      return this.dir
    },
  },
  watch: {
    isExpert(value) {
      if (!value) {
        this.fields = [
          "show_details",
          { key: "name", label: "Full Name", sortable: true },
          // { key: "candidate.title", label: "DESIGNATION", sortable: true },
          // { key: "candidate.country", label: "Country", sortable: true },
          {
            key: "candidate.current_annual_salary",
            label: "COMPENSATION",
            sortable: true,
          },
        ]
      } else {
        this.fields = [
          "show_details",
          { key: "name", label: "Full Name", sortable: true },
          // { key: "candidate.title", label: "DESIGNATION", sortable: true },
          // { key: "candidate.country", label: "Country", sortable: true },
          {
            key: "candidate.charge_per_hour",
            label: "HOURLY RATE",
            sortable: true,
          },
        ]
      }
    },
  },
  mounted() {
    // Set the initial number of items
    this.getCandidates(this.searchFilter)
    this.getIndustries()
    this.getFunctions()
    this.getSubFunctions()
    this.getSkills()
  },
  created() {},
  updated() {
    // this.row.toggleDetails = true
  },

  methods: {
    getSelected(option) {
      return (option && option.name) || ""
    },
    handleSearch() {
      let filter = "?"
      let filterCount = 0
      if (this.form.searchName) {
        filterCount += 1
        if (filterCount > 1) {
          filter += "&"
        }
        filter += `filter[name]=${this.form.searchName}`
      }
      if (this.form.selectedJobTitle) {
        filterCount += 1
        if (filterCount > 1) {
          filter += "&"
        }
        filter += `filter[job_title]=${this.form.selectedJobTitle}`
      }
      if (this.form.selectInstitute) {
        filterCount += 1
        if (filterCount > 1) {
          filter += "&"
        }
        filter += `filter[education_institute]=${this.form.selectInstitute}`
      }
      if (this.form.graduationStartYear) {
        filterCount += 1
        if (filterCount > 1) {
          filter += "&"
        }
        filter += `filter[education_from]=${this.form.graduationStartYear}`
      }
      if (this.form.graduationEndYear) {
        filterCount += 1
        if (filterCount > 1) {
          filter += "&"
        }
        filter += `filter[education_to]=${this.form.graduationEndYear}`
      }
      if (this.form.location) {
        filterCount += 1
        if (filterCount > 1) {
          filter += "&"
        }
        filter += `filter[location]=${this.form.location}`
      }
      if (this.form.company === "true") {
        filterCount += 1
        if (filterCount > 1) {
          filter += "&"
        }
        filter += `filter[present_working]=${this.form.company}`
      }
      if (this.form.selectedCompany) {
        filterCount += 1
        if (filterCount > 1) {
          filter += "&"
        }
        filter += `filter[company]=${this.form.selectedCompany}`
      }
      if (
        this.form.selectedIndustries &&
        this.form.selectedIndustries.length !== 0
      ) {
        filterCount += 1
        if (filterCount > 1) {
          filter += "&"
        }

        let txt = ""
        let filteredCount = 0
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.form.selectedIndustries.length; i++) {
          filteredCount += 1
          if (filteredCount > 1) {
            txt += ","
          }
          txt += this.form.selectedIndustries[i].name
        }
        filter += `filter[industry]=${txt}`
      }

      if (
        this.form.selectedFunctions &&
        this.form.selectedFunctions.length !== 0
      ) {
        filterCount += 1
        if (filterCount > 1) {
          filter += "&"
        }

        let txt = ""
        let filteredCount = 0
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.form.selectedFunctions.length; i++) {
          filteredCount += 1
          if (filteredCount > 1) {
            txt += ","
          }
          txt += this.form.selectedFunctions[i].name
        }
        filter += `filter[functions]=${txt}`
      }

      if (
        this.form.selectedSubFunctions &&
        this.form.selectedSubFunctions.length !== 0
      ) {
        filterCount += 1
        if (filterCount > 1) {
          filter += "&"
        }

        let txt = ""
        let filteredCount = 0
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.form.selectedSubFunctions.length; i++) {
          filteredCount += 1
          if (filteredCount > 1) {
            txt += ","
          }
          txt += this.form.selectedSubFunctions[i].name
        }
        filter += `filter[sub_functions]=${txt}`
      }

      if (this.form.selectedSkills.length !== 0) {
        filterCount += 1
        if (filterCount > 1) {
          filter += "&"
        }

        let txt = ""
        let filteredCount = 0
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.form.selectedSkills.length; i++) {
          filteredCount += 1
          if (filteredCount > 1) {
            txt += ","
          }
          txt += this.form.selectedSkills[i].name
        }
        filter += `filter[skills]=${txt}`
      }

      this.getCandidates(filter)
    },
    showAllExperience() {
      this.showExperience = undefined
    },
    showLessExperience() {
      this.showExperience = 3
    },
    handleGraduationStartYear() {
      const startYear = Math.floor(this.graduationStartYear)
      this.graduation[0] = startYear
    },
    handleGraduationEndYear() {
      this.value2 = Math.floor(this.graduationEndYear)
      const endYear = Math.floor(this.graduationEndYear)
      this.graduation[1] = endYear
    },
    showAllQualification() {
      this.showQualification = undefined
    },
    showLessQualification() {
      this.showQualification = 3
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit("bv::show::modal", this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ""
      this.infoModal.content = ""
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async getIndustries() {
      axios
        .get(`/industries`)
        .then((res) => {
          this.industries = res.data.industries
        })
        .catch((error) => {
          console.log(error)
          errorResponse(error, this.$router)
        })
    },
    async getFunctions() {
      axios
        .get(`/functions`)
        .then((res) => {
          this.functions = res.data.candidateFunctions
        })
        .catch((error) => {
          console.log(error)
          errorResponse(error, this.$router)
        })
    },
    async getSubFunctions() {
      axios
        .get(`/sub_functions`)
        .then((res) => {
          this.subFunctions = res.data.sub_functions
        })
        .catch((error) => {
          console.log(error)
          errorResponse(error, this.$router)
        })
    },
    async getSkills() {
      axios
        .get(`/skills`)
        .then((res) => {
          this.skills = res.data.skills
        })
        .catch((error) => {
          console.log(error)
          errorResponse(error, this.$router)
        })
    },
    async getCandidates(filters) {
      axios
        .get(`/index${filters}`)
        .then((res) => {
          this.items = res.data
          this.totalRows = this.items.length

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Candidate List`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully fetched Candidate List!`,
            },
          })
        })
        .catch((error) => {
          console.log(error)
          errorResponse(error, this.$router)
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-slider.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.bor-1 {
  border-right: 1px solid lightgray;
}

.in__line {
  display: flex;
  flex-direction: row;
}

.y-center {
  display: flex;
  flex-direction: column;

  justify-content: center;
}

.title__box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.card__box {
  display: flex;
  justify-content: space-between;
}

.card__left {
  flex: 1;
}
.loc {
  font-size: 11px;
  padding-right: 5px;
}

.font-11 {
  font-size: 11px;
}

.bold {
  font-family: bold;
}
.inline {
  display: inline !important;
}

.name {
  margin-bottom: 0px;
}
.timeline-item {
  padding-bottom: 5px !important;
}

// .card-body {
//   padding: 0px !important;
// }
.experience_btn {
  cursor: pointer;
}
.location {
  display: inline !important;
}
.icon {
  margin-top: 5px;
}
.executive {
  margin-right: 5px;
  font-size: 12px;
}
</style>

Row select support Selection mode: multi ID AVAT
